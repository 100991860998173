<script setup lang="ts">
import PrettyJson from "@/components/basic/PrettyJson.vue";
import {useStore} from "@/store";
import {computed} from "vue";
import {useRouter} from "vue-router/composables";

const store = useStore();
const router = useRouter();

if(!store.state.permissions.superAdmin) {
  router.push('/');
}

const storeData = computed(() => {
  return {
    user: store.state.user,
    project: store.state.project,
    organization: store.state.organization,
  }
})
</script>

<template>
  <div class="sandbox">
    <PrettyJson :value="storeData" />
  </div>
</template>

<style scoped>
.sandbox {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
